//GameGroup.js
import config from '../../config';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import GameGroupsMenu from '../elems/GameGroupsMenu';
import GameList from '../elems/GameList';
import Providers from '../elems/Providers';
import withAuth from '../hoc/withAuth';  // Import the HOC
import { fetchBonusList, connectWebSocket } from '../../redux/actions/bonus/bonusListActions';
import { selectBonusList, selectBonusListLoading, selectBonusListError } from '../../redux/selectors/bonus/bonusListSelectors';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const GameGroup = () => {
    //   let { slug } = useParams();
    const dispatch = useDispatch();
    const bonusList = useSelector(selectBonusList);
    const loading = useSelector(selectBonusListLoading);
    const error = useSelector(selectBonusListError);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    //Пример. Локальное состояние. loading и error
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(false);
    // const [groupId, setGroupId] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            console.log('home page', token);
            const closeWebSocket = dispatch(connectWebSocket(token));

            return () => {
                if (closeWebSocket && typeof closeWebSocket === 'function') {
                    closeWebSocket();
                }
            };
        }
    }, [dispatch, token, isAuthenticated]);


    useEffect(() => {
        console.log('bonusList:', bonusList);

    }, [bonusList]);

    const onClickMoreInfo = async (e) => {
        e.preventDefault(); // Предотвращение дефолтного поведения кнопки (если требуется)
        console.log(`onClickMoreInfo`);
    };

    const onClickGetBonus = async (e) => {
        e.preventDefault();
        console.log(`onClickGetBonus`);
    };

    //   const handlePlayClick = (bonus) => {
    //     console.log(`Play clicked for bonus: ${bonus.name}`);
    //   };

    return (
        <>
            <div>
                <h1>Bonuses</h1>
                {/* <GameGroupsMenu />
                <Providers /> */}
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) :
                <div className="row wrap-bonus">
                    {bonusList && bonusList.map((bonus, i) => (
                        <div key={i} className="col-4">
                            <div className="card">
                                {bonus.image_link !== null && bonus.image_link !== '' ?
                                <img className='card-img-top' src={`${config.fileUrls.bonusFolder}${bonus.image_link}`} alt={bonus.bonusLangs[0].name} />
                                :
                                <div className="defaultImage">
                                    <h5>Bonus #{bonus.id}</h5>
                                </div>
                                }
                                <div className="card-body">
                                    <button type="button" className="btn btn-warning">Wellcome bonus group</button>
                                    <h4 className="card-title">{bonus.bonusLangs[0].name}</h4>
                                    <p className="card-text">Сумма бонуса {bonus.bonusBudgets.amount} {bonus.bonusBudgets.currency.code}</p>
                                    {/* <p className="card-text">Отыгрыш бонуса</p> */}
                                    <p className="card-text">Фриспины {bonus.bonusFreespins.spin_count ? bonus.bonusFreespins.spin_count : '-'}</p>
                                    <p className="card-text">Вейджеры фриспинов {bonus.bonusFreespins.wager_multiplier ? bonus.bonusFreespins.wager_multiplier : '-'}</p>
                                    <div className="bonus-card-buttons d-flex justify-content-between">
                                        <button type="button" className="btn btn-primary" onClick={(e, bonus) => onClickMoreInfo(e, bonus)}>Подробнее</button>
                                        <button type="button" className="btn btn-warning" onClick={(e, bonus) => onClickGetBonus(e, bonus)}>Получить бонус</button>
                                    </div>
                                </div>
                            </div>

                        </div>



                    ))}
                </div>
            }
        </>
    );
}

// export default withAuth(GameGroup);
export default GameGroup;