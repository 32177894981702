// src/redux/reducers.js
import { combineReducers } from 'redux';
import loginReducer from './reducers/site/loginReducer';
import logoutReducer from './reducers/site/logoutReducer';
import balanceReducer from './reducers/balanceReducer';
import balancesReducer from './reducers/balancesReducer';
import providerReducer from './reducers/providerReducer';
import gameListReducer from './reducers/game/gameListReducer';
import gameGroupsReducer from './reducers/game/gameGroupsReducer';
import bonusListReducer from './reducers/bonus/bonusListReducer';
import vipClubInfoReducer from './reducers/bonus/vipClubInfoReducer';
import listFavoriteGamesReducer from './reducers/player/listFavoriteGamesReducer';
import myProgressReducer from './reducers/player/myProgressReducer';
import listBalancesReducer from './reducers/player/listBalancesReducer';
import getListDocumentsReducer from './reducers/document/getListDocumentsReducer';
import getDocumentSettingTypesReducer from './reducers/document/getDocumentSettingTypesReducer';
import {transactionsReducer} from "./slices/transactionsSlice";


const rootReducer = combineReducers({
    loginReducer,
    logoutReducer,
    balanceReducer,
    providerReducer,
    balancesReducer,
    gameListReducer,
    gameGroupsReducer,
    listFavoriteGamesReducer,
    bonusListReducer,
    getListDocumentsReducer,
    getDocumentSettingTypesReducer,
    myProgressReducer,
    listBalancesReducer,
    vipClubInfoReducer,
    transactions: transactionsReducer
});

export default rootReducer;
