// bonusListReducer.js
import { FETCH_BONUSLIST_REQUEST, FETCH_BONUSLIST_SUCCESS, FETCH_BONUSLIST_FAILURE } from '../../actions/bonus/bonusListActions';

const initialState = {
    bonusList: null,
    loading: false,
    error: null,
    messages: null,
  };
  
  const bonusListReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BONUSLIST_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_BONUSLIST_SUCCESS:
        return {
          ...state,
          loading: false,
          bonusList: action.payload,
        };
      case FETCH_BONUSLIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default bonusListReducer;
  