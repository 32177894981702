// components/elems/GameList.js
// components/elems/GameList.js
import React from 'react';
import config from '../../config';

const GameList = ({ gameList, onStarClick, onDemoClick, onPlayClick }) => {
    if (!gameList || gameList.length === 0) {
        return <p>No games available</p>;
    }
    
  
    return (
    <div className="row wrap-games">
      {gameList !== null && gameList.map((game, i) => (
        <div key={i} className="col-3">
          <div className="item-game">
            <div className='item-game_pic'>
              <img className='' src={`${config.fileUrls.game}${game.image_path_album}`} alt={game.name} />
            </div>
            <div className='item-game_info p-2 d-flex flex-column'>
              <div className='d-flex justify-content-end'>
                <i className="fa fa-star-o" onClick={() => onStarClick(game)}></i>
              </div>
              <div className=''>
                <h5>{game.name}</h5>
              </div>
              <div className='font-weight-bold'>
                <span style={{width: 1 + 'rem'}}>{game.group_name}</span>
              </div>
              <div className='d-flex justify-content-between mt-auto'>
                <a href='#' type="button" className="btn btn-primary" onClick={() => onDemoClick(game)}>Demo</a>
                <a href='#' type="button" className="btn btn-success" onClick={() => onPlayClick(game)}>Play</a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GameList;
