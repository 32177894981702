import React from 'react'
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from '@mui/material'
import {Data, Order} from './types'
import {visuallyHidden} from '@mui/utils'
import {getComparator} from './sortingUtils'

const SortableTable: React.FC<SortableTableProps> = ({data, order, orderBy, setOrder, setOrderBy}) => {
  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedData = [...data].sort(getComparator(order, orderBy))

  return (
    <TableContainer>
      <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'id'}
                direction={orderBy === 'id' ? order : 'asc'}
                onClick={() => handleRequestSort('id')}
              >
                ID
                {orderBy === 'id' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'date'}
                direction={orderBy === 'date' ? order : 'asc'}
                onClick={() => handleRequestSort('date')}
              >
                Date
                {orderBy === 'date' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'amount'}
                direction={orderBy === 'amount' ? order : 'asc'}
                onClick={() => handleRequestSort('amount')}
              >
                Amount
                {orderBy === 'amount' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'currency_title'}
                direction={orderBy === 'currency_title' ? order : 'asc'}
                onClick={() => handleRequestSort('currency_title')}
              >
                Currency
                {orderBy === 'currency_title' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'status'}
                direction={orderBy === 'status' ? order : 'asc'}
                onClick={() => handleRequestSort('status')}
              >
                Status
                {orderBy === 'status' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => (
            <TableRow hover key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{new Date(row.date * 1000).toLocaleDateString()}</TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.currency_title}</TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type SortableTableProps = {
  data: Data[]
  order: Order
  orderBy: keyof Data
  setOrder: (value: Order) => void
  setOrderBy: (value: keyof Data) => void
}

export default SortableTable
