import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { fetchLogout } from '../../redux/actions/site/logoutActions';
import { fetchBalances, connectWebSocket } from '../../redux/actions/balancesActions';
import { selectBalances, selectLoading, selectError } from '../../redux/selectors/balancesSelectors';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const Navigation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdownMainBalance, setShowDropdownMainBalance] = useState(false);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);
    const balances = useSelector(selectBalances);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const [hideZeroBalance, setHideZeroBalance] = useState(false);

    const handleHideZeroBalanceToggle = () => {
        setHideZeroBalance(!hideZeroBalance);
    };

    // const handleLogout = async (e) => {
    //     e.preventDefault();
    //     dispatch(fetchLogout(token));
    //     console.log('isAuthenticated: ' + isAuthenticated);
    //     if (!isAuthenticated) {
    //         navigate('/login');
    //     }
    // };

    const handleLogout = async (e) => {
        e.preventDefault();
        await dispatch(fetchLogout(token));

        console.log('isAuthenticated: ' + isAuthenticated);
        // Здесь мы просто используем существующую переменную isAuthenticated
        if (!isAuthenticated) {
            navigate('/login');
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchBalances(token)); // Fetch balances once
            const closeWebSocket = dispatch(connectWebSocket(token)); // Connect WebSocket
            return () => {
                closeWebSocket();
            };
        }
    }, [dispatch, token, isAuthenticated]);

    useEffect(() => {
        console.log(balances); // Log balances after they are updated
    }, [balances]);


    // ---------------
    // TODO сделать какой то блок Alert в котором будет выводиться ошибка логаута
    // ---------------


    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">Platform</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li> */}
                        {isAuthenticated ? (
                            <>
                                <li className="nav-item" style={{ paddingRight: "40px" }}>
                                    <div className={"dropdown" + (showDropdown ? " show" : "")}>
                                        <a className="nav-link btn text-light dropdown-toggle" href="#"
                                            onClick={() => {
                                                setShowDropdown(!showDropdown);
                                                // console.log('showDropdown:' + showDropdown);
                                            }}>Profile</a>
                                        <div className={"dropdown-menu dropdown-menu-left dropdown-menu-arrow" + (showDropdown ? " show" : "")}>
                                            <Link className="dropdown-item" to="/notifications">Notifications</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="/favorite-games">Favorite Games</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="/accounts">Accounts</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="/personal-information">Personal Information</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="/documents">Documents</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="/transactions">Transaction History</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="/bonus-history">Bonus History</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="/responsible-gaming">Responsible Gaming</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to="/deposit">Deposit</Link>
                                        </div>
                                    </div>
                                </li>

                                {/* Balances */}
                                {balances ? (
                                    Object.entries(balances).map(([key, balance]) => {
                                        // Main balance
                                        if (key === 'main_balance') {

                                            return (
                                                <li className="nav-item" style={{ paddingRight: "40px" }} key={key}>
                                                    <div className={"dropdown" + (showDropdownMainBalance ? " show" : "")}>
                                                        <a className="nav-link btn text-light dropdown-toggle d-flex align-items-center" href="#"
                                                            onClick={() => {
                                                                // console.log('showDropdownMainBalance:' + showDropdownMainBalance);
                                                                setShowDropdownMainBalance(!showDropdownMainBalance);
                                                            }}>
                                                            <div>
                                                                <p className='mb-0'>REAL</p>
                                                                <p className='mb-0'>
                                                                    <span>{balance.amount}{balance.currency}</span>
                                                                </p>
                                                            </div>

                                                        </a>
                                                        <div className={"dropdown-menu dropdown-menu-left dropdown-menu-arrow" + (showDropdownMainBalance ? " show" : "")}>
                                                            {hideZeroBalance && balance.amount == 0 ? null : (
                                                                <>
                                                                    <div className='d-flex justify-content-between p-2'>
                                                                        <span>{balance.currency}</span>
                                                                        <span>{balance.currency_symbol ? balance.currency_symbol : ''}{balance.amount}</span>
                                                                    </div>
                                                                    <div className="dropdown-divider"></div>
                                                                </>
                                                            )}
                                                            
                                                            <div className='d-flex justify-content-between p-2'>
                                                                {/* <span>Show crypto fiat</span> */}
                                                                <span>Hide zero balance</span>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={hideZeroBalance}
                                                                    onChange={handleHideZeroBalanceToggle}
                                                                />
                                                            </div>


                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                            // Main balance
                                        } else if (key === 'bonus_balance') {
                                            return (
                                                <li className="nav-item" style={{ paddingRight: "40px" }} key={key}>
                                                    <div className="text-light align-items-center p-2">
                                                        <p className='mb-0'>BONUS</p>
                                                        <p className='mb-0'>
                                                            <span>{balance.amount}{balance.currency}</span>
                                                        </p>
                                                    </div>
                                                </li>
                                            );
                                        }
                                        // --- Main balance
                                    })
                                ) : (
                                    <p>No data available</p>
                                )}

                                <li className="nav-item">
                                    <button className="nav-link text-light" onClick={handleLogout}>Logout</button>
                                </li>
                            </>

                        ) : (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login">Login</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/signup">Signup</Link>
                                </li>
                            </>
                        )}

                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
