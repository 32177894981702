import {useMemo, useState} from 'react'
import {Transaction} from "../../../../api/types"

// Hook for filters
export const useFilters = () => {
  const [idFilter, setIdFilter] = useState('')
  const [dateFilter, setDateFilter] = useState('')
  const [amountFilter, setAmountFilter] = useState('')
  const [currencyFilter, setCurrencyFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  return {
    idFilter,
    setIdFilter,
    dateFilter,
    setDateFilter,
    amountFilter,
    setAmountFilter,
    currencyFilter,
    setCurrencyFilter,
    statusFilter,
    setStatusFilter,
  }
}

// Hook for sorting
export const useSorting = () => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<OrderBy>('date')

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
  }
}

// Hook for pagination
export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(2)

  return {
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
  }
}

export const useFilteredData = ({
                                  data,
                                  idFilter,
                                  dateFilter,
                                  amountFilter,
                                  currencyFilter,
                                  statusFilter,
                                }: UseFilteredDataProps) => {
  return useMemo(() => {
    if (!data.length) return []

    return data.filter((transaction) => {
      const matchesId = !idFilter || transaction.id.toString().includes(idFilter)
      const matchesDate = !dateFilter || new Date(transaction.date * 1000).toLocaleDateString().includes(dateFilter)
      const matchesAmount = !amountFilter || transaction.amount.toString().includes(amountFilter)
      const matchesCurrency = !currencyFilter || transaction.currency_title.includes(currencyFilter)
      const matchesStatus = !statusFilter || transaction.status.includes(statusFilter)

      return matchesId && matchesDate && matchesAmount && matchesCurrency && matchesStatus
    })
  }, [data, idFilter, dateFilter, amountFilter, currencyFilter, statusFilter])
}

type Order = 'asc' | 'desc';
type OrderBy = 'id' | 'date' | 'amount' | 'currency_title' | 'status';
type UseFilteredDataProps = {
  data: Transaction[];
  idFilter: string;
  dateFilter: string;
  amountFilter: string;
  currencyFilter: string;
  statusFilter: string;
};