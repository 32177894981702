// components/template/Sidebar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { fetchLogout } from '../../redux/actions/site/logoutActions';
import { setShowModalMyProgress, setPositionStylesModalMyProgress } from '../../redux/actions/player/myProgressActions';
import { selectShowModalMyProgress, selectPositionStylesModalMyProgress } from '../../redux/selectors/player/myProgressSelectors';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showModalMyProgress = useSelector(selectShowModalMyProgress);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    useEffect(() => {
        // console.log('init showModalMyProgress', showModalMyProgress);
    }, [showModalMyProgress]);

    const handleLogout = async (e) => {
        e.preventDefault();
        await dispatch(fetchLogout(token));

        console.log('isAuthenticated: ' + isAuthenticated);
        // Здесь мы просто используем существующую переменную isAuthenticated
        if (!isAuthenticated) {
            navigate('/login');
        }
    };

    const handleBonuses = async (e) => {
        e.preventDefault();
        navigate('/bonuses');
    };

    const handleVipClub = async (e) => {
        e.preventDefault();
        navigate('/vip');
    };

    // const handleMyProgress = async (e) => {
    //     e.preventDefault();
    //     dispatch(setShowModalMyProgress(!showModalMyProgress));
    //     console.log('showModalMyProgress', showModalMyProgress);
        
    // };

    const handleMyProgress = (e) => {
        e.preventDefault();

        // Получаем координаты элемента
        const rect = e.target.getBoundingClientRect();

        // Вычисляем позицию, где нужно отобразить модальное окно
        const positionStyles = {
            // top: `${rect.bottom + window.scrollY}px`, // Чтобы окно было ниже элемента
            top: `${rect.top + window.scrollY}px`,
            // left: `${rect.left + window.scrollX}px`, // Чтобы окно было по левому краю элемента
            left: `${rect.right + window.scrollX + 20}px`, 
        };

        // Устанавливаем стили позиции модального окна в Redux
        dispatch(setPositionStylesModalMyProgress(positionStyles));

        // Показываем или скрываем модальное окно
        dispatch(setShowModalMyProgress(!showModalMyProgress));
    };

    return (
        <div className="sidebar">
            <ul>
                <li className="nav-item">
                    <Link className="nav-link" to="/">Home</Link>
                </li>
                {isAuthenticated ? (
                    <>
                        <li className="nav-item">
                            <button className="nav-link" onClick={handleLogout}>Logout</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" to="/bonuses" onClick={handleBonuses}>Bonuses</button>
                        </li>
                        <li className="nav-item">

                            <button className="nav-link" onClick={handleMyProgress}>My Progress</button>

                            <button className="nav-link" to="/vip" onClick={handleVipClub}>Vip Club</button>

                        </li>
                        {/* <li className="nav-item">
                            <button className="nav-link" to="/bonuses" onClick={handleBonuses}>Bonuses</button>
                        </li> */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/transactions">Transactions</Link>
                        </li>
                    </>
                ) : (
                    <>
                        <li className="nav-item">
                        <Link className="nav-link" to="/login">Login</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/signup">Signup</Link>
                        </li>

                    </>
                )}
                <li className="nav-item">
                    <Link className="nav-link" to="/faq">FAQ</Link>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;