// bonusListActions.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_BONUSLIST_REQUEST = 'FETCH_BONUSLIST_REQUEST';
export const FETCH_BONUSLIST_SUCCESS = 'FETCH_BONUSLIST_SUCCESS';
export const FETCH_BONUSLIST_FAILURE = 'FETCH_BONUSLIST_FAILURE';

// Экшены для получения данных
export const fetchBonusListRequest = () => ({
  type: FETCH_BONUSLIST_REQUEST,
});

export const fetchBonusListSuccess = (bonusList) => ({
  type: FETCH_BONUSLIST_SUCCESS,
  payload: bonusList,
});

export const fetchBonusListFailure = (error) => ({
  type: FETCH_BONUSLIST_FAILURE,
  payload: error,
});

export const fetchBonusList = (token, language = null) => async (dispatch) => {
  dispatch(fetchBonusListRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/bonus/list`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      }
    });
    
    dispatch(fetchBonusListSuccess(response.bonusList.data));
  } catch (error) {
    dispatch(fetchBonusListFailure(error.message));
  }
};

// Экшены для WebSocket
export const connectWebSocket = (token) => {
  return (dispatch) => {
    const ws = new WebSocket( config.serverWebsocketUrl );

    ws.onopen = () => {
      console.log('Connected to WebSocket server getBonusList');
      ws.send(JSON.stringify({ type: 'getBonusList', token }));
      
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getBonusList:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.type === 'getBonusList') {
          console.log('BonusList received:', data.bonusList.data);
          dispatch(fetchBonusListSuccess(data.bonusList.data));
        } else if (data.type === 'error') {
          dispatch(fetchBonusListFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
