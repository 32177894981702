// components/layouts/MainLayout.js
import React from 'react';
import Navigation from './Navigation';
import Sidebar from './Sidebar';
import MyProgressModal from '../elems/MyProgressModal';

const MainLayout = ({ children }) => {
    return (
        <div className="main-layout">
            <Navigation />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <main className="content">
                            <MyProgressModal></MyProgressModal>
                            {children}
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
