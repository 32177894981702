import React from 'react';

const Signup = () => {
    return (
        <div className="container">
            <h1>Signup</h1>

            <p>Please fill out the following fields to signup:</p>

            <div className="row">
                <div className="col-lg-5">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input type="text" id="username" className="form-control" name="username" autoFocus />
                            {/* Вывод сообщения об ошибке валидации */}
                            <div className="invalid-feedback">Username is required.</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" id="email" className="form-control" name="email" />
                            {/* Вывод сообщения об ошибке валидации */}
                            <div className="invalid-feedback">Please provide a valid email address.</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input type="password" id="password" className="form-control" name="password" />
                            {/* Вывод сообщения об ошибке валидации */}
                            <div className="invalid-feedback">Password is required.</div>
                        </div>
                        <button type="submit" className="btn btn-primary">Signup</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
