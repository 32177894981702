//Provider.js
import React, { useState, useEffect } from 'react';
import { useParams  } from 'react-router-dom';
import GameGroupsMenu from '../elems/GameGroupsMenu';
import Providers from '../elems/Providers';
import withAuth from '../hoc/withAuth';  // Import the HOC

const Provider = () => {
  let { id } = useParams();

  // Пример. Потом ошибки и загрузку выводим из хранилища.
  // const loading = useSelector(selectLoading);
  // const error = useSelector(selectError);

  //Пример. Локальное состояние. loading и error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 200);
    
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : <div>
        <h1>Provider ID: {id}</h1>
        {/* <GameGroupsMenu />
        <Providers /> */}
      </div>
      }
    </>
  );
}

// export default withAuth(Provider);
export default Provider;