import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import MainLayout from './components/layouts/MainLayout';
import {
  Accounts,
  BasePage,
  Bonuses,
  BonusHistory,
  Documents,
  FavoriteGames,
  GameGroup,
  Home,
  Login,
  Notifications,
  PersonalInformation,
  Provider,
  ResponsibleGaming,
  Signup,
  VipClub
} from './components/pages';
import {Home as HomeGuest} from './components/pages/guest';
import {useDispatch, useSelector} from 'react-redux';
//инициализация селекторов
import {selectBonusList} from './redux/selectors/bonus/bonusListSelectors';
import {selectListFavoriteGames} from './redux/selectors/player/listFavoriteGamesSelectors';
import StateLogger from './helpers/components/StateLogger';
import {selectIsAuthenticated} from './redux/selectors/site/loginSelectors';
import {Faq} from "./components/pages/Faq.tsx";
import {NotFound} from "./components/pages/NotFound";
import {Transactions} from "./components/pages/Transactions/Transactions";

function App() {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  //инициализация селекторов
  const bonusList = useSelector(selectBonusList);
  const listFavoriteGames = useSelector(selectListFavoriteGames);

  useEffect(() => {
    // В момент загрузки страницы
    // Токен сохраняется при удачном логине в локальном хранилище и в редаксе. Если страницу обновить,
    // то в редаксе теряется токен, поэтому он снова тут устанавливается, если он есть в локалсторе.
    // Таким образом при обновлении страницы пользователь не разлогинивается
  
    // if (storedToken) {
    //   dispatch(fetchLoginSuccess(storedToken));
    // }
    console.log('isAuthenticated', isAuthenticated);
  }, []);


  const BasePageRoute = ({ registered: RegisteredComponent, guest: GuestComponent }) => (
    <BasePage>
      {isAuthenticated ? <RegisteredComponent /> : <GuestComponent />}
    </BasePage>
  );

  return (
    <>
    <StateLogger />
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/" element={<BasePageRoute registered={Home} guest={HomeGuest} />} />


          {/* 
          Пока выводим вручную. Потом урлы можно будет подгружать из хранилища
          https://sloterra.com/notifications

          https://sloterra.com/favoriteGames

          https://sloterra.com/accounts

          https://sloterra.com/personalInformation

          https://sloterra.com/documents

          https://sloterra.com/transactions

          https://sloterra.com/bonusHistory

          https://sloterra.com/responsibleGaming

          https://sloterra.com/deposit 
          */}

          <Route path="/notifications" element={<BasePageRoute registered={Notifications} guest={Login} />} />
          <Route path="/favorite-games" element={<BasePageRoute registered={FavoriteGames} guest={Login} />} />
          <Route path="/accounts" element={<BasePageRoute registered={Accounts} guest={Login} />} />
          <Route path="/personal-information" element={<BasePageRoute registered={PersonalInformation} guest={Login} />} />
          <Route path="/documents" element={<BasePageRoute registered={Documents} guest={Login} />} />
          <Route path="/transactions" element={<BasePageRoute registered={Transactions} guest={Login} />} />
          <Route path="/bonus-history" element={<BasePageRoute registered={BonusHistory} guest={Login}/>} />
          <Route path="/responsible-gaming" element={<BasePageRoute registered={ResponsibleGaming} guest={Login} />} />
          <Route path="/bonuses" element={<BasePageRoute registered={Bonuses} guest={Login} />} />
          <Route path="/vip" element={<BasePageRoute registered={VipClub} guest={Login} />} />

          {/* game provider */}
          <Route path="/provider/:id" element={<BasePageRoute registered={Provider} guest={Login} />} />

          {/* game provider */}
          <Route path="/game-group/:slug" element={<BasePageRoute registered={GameGroup} guest={Login} />} />

          <Route path="/faq" element={<Faq />} />

          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
    </>
  );
}

export default App;
