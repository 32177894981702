// components/elems/Providers.js
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviders, connectWebSocket } from '../../redux/actions/providerActions';
import { selectProviders, selectProvidersLoading, selectProvidersError } from '../../redux/selectors/providerSelectors';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const Providers = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gameProviders = useSelector(selectProviders);
    const providersLoading = useSelector(selectProvidersLoading);
    const providersError = useSelector(selectProvidersError);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    useEffect(() => {
        if (isAuthenticated) {
            // dispatch(fetchProviders(token));
            const closeWebSocket = dispatch(connectWebSocket(token));

            return () => {
                closeWebSocket();
            };
        }
        // console.log('providers', providers);

    }, [dispatch, token, isAuthenticated]);


    useEffect(() => {
        
        console.log('gameProviders', gameProviders);
    }, [dispatch, gameProviders]);


    return (
        <div className="block-providers mb-2">
            <div className="card">
                <div className="card-body">
                    <h5>Game providers</h5>
                    {providersLoading ? (
                        <p>Loading...</p>
                    ) : providersError ? (
                        <p>Error: {providersError}</p>
                    ) : gameProviders !== null ? (
                        // Если `providers` не null, рендерим список провайдеров
                        <div className="horizontal-scroll-container">
                            {gameProviders && gameProviders.map((provider, i) => (
                                <div key={i}>
                                    <Link to={`/provider/${provider.id}`}>
                                        {provider.name}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No providers!</p>
                    )}


                </div>
            </div>
        </div>
    );
};

export default Providers;