// gameListReducer.js
import { FETCH_GAMELIST_REQUEST, FETCH_GAMELIST_SUCCESS, FETCH_GAMELIST_FAILURE } from '../../actions/game/gameListActions';

const initialState = {
    gameList: null,
    loading: false,
    error: null,
    messages: null,
  };
  
  const gameListReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_GAMELIST_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_GAMELIST_SUCCESS:
        return {
          ...state,
          loading: false,
          gameList: action.payload,
        };
      case FETCH_GAMELIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default gameListReducer;
  