import { Data, Order } from './types'

// Returns a comparator function to be used for sorting data
export function getComparator<Key extends keyof Data>(
  order: Order,            // 'asc' for ascending or 'desc' for descending order
  orderBy: Key             // The field (key) by which data will be sorted
): (a: Data, b: Data) => number {
  // If order is 'desc', use descendingComparator, otherwise invert the result for ascending order
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Function to compare two data entries in descending order based on a specific field (orderBy)
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1; // Return -1 if `b` should come before `a` in sorted order
  }
  if (b[orderBy] > a[orderBy]) {
    return 1; // Return 1 if `a` should come before `b`
  }
  return 0; // Return 0 if both values are equal, keeping their current order
}
