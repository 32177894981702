import axios from "axios";
import { useEffect, useState } from "react";
import config from '../../config';
import React from 'react';
import {FaqApiResponse, FaqItem} from "../../api/types"

export const Faq = () => {
    const [data, setData] = useState<FaqApiResponse | null>(null); // Initialize state with ApiResponse type

    useEffect(() => {
        const fetchFaqs = async () => {
            console.log("Fetching FAQs...");
            try {
                const response = await axios.get<FaqApiResponse>(`${config.apiBaseUrl}/faq/list`); // Specify the expected response type

                if (response.data && response.data.success) {
                    setData(response.data); // Set the data from the response
                } else {
                    console.error("Error fetching FAQ: Unsuccessful response", response);
                }
            } catch (error) {
                console.error("Error fetching FAQ:", error);
            }
        };

        fetchFaqs();
    }, []);

    return (
      <div>
          <h1>FAQ</h1>
          {data ? (
            // Check if data is not null and display FAQ names
            data.data.map((item: FaqItem) => (
              <div key={item.id}>
                  <div>name: {item.name}</div>
                  <div>description: {item.description}</div>
                  <div>slug: {item.slug}</div>
              </div>
            ))
          ) : (
            <p>Loading FAQs...</p>
          )}
      </div>
    );
};
