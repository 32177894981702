//BasePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { connectBalanceWebSocket } from '../../redux/actions/balanceActions';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const BasePage = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    useEffect(() => {
        if (!isAuthenticated) {
            // dispatch(fetchLogout());
            // navigate('/login');
        } else {
            const closeBalancWebSocket = dispatch(connectBalanceWebSocket(token));

            return () => {
                closeBalancWebSocket();
            };
        }
    }, [isAuthenticated, token, navigate, dispatch]);

    // if (!token) {
    //     return null; // Или можете вернуть какой-то компонент загрузки
    // }

    return <>{children}</>;
};

export default BasePage;
