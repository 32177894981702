import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogin } from '../../redux/actions/site/loginActions';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { selectIsAuthenticated, selectToken, selectLoginErrors, selectIsLoadingLogin, selectHasTokenInLocalStorage } from '../../redux/selectors/site/loginSelectors';


const Login = () => {
    const dispatch = useDispatch();
    // const token = useSelector(state => state.loginReducer.token);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const storedToken = useSelector(selectToken);
    const loginErrors = useSelector(selectLoginErrors);
    const isLoadingLogin = useSelector(selectIsLoadingLogin);
    const hasTokenInLocalStorage = useSelector(selectHasTokenInLocalStorage);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [ip, setIp] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('handleSubmit');
        dispatch(fetchLogin(email, password, ip, navigate));
    };

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIp(response.data.ip);
                // setIp('10.10.1.1.1');
            } catch (error) {
                console.error('Error fetching IP:', error);
            }
        };

        fetchIp();
    }, []);

    return (
        <div className="container">
            <h1>Login</h1>
            {/* {token && (
                <div className="alert alert-success" role="alert">
                    Token: {token}
                </div>
            )} */}
            <p>Please fill out the following fields to login:</p>

            <div className="row">
                <div className="col-lg-5">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                id="email"
                                className={`form-control ${loginErrors && loginErrors.email && 'is-invalid'}`}
                                name="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {/* Вывод сообщения об ошибке валидации */}
                            {loginErrors && loginErrors.email && <div className="invalid-feedback">{loginErrors.email}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
                                type="password"
                                id="password"
                                className={`form-control ${loginErrors && loginErrors.password && 'is-invalid'}`}
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {/* Вывод сообщения об ошибке валидации */}
                            {loginErrors && loginErrors.password && <div className="invalid-feedback">{loginErrors.password[0]}</div>}
                        </div>
                        <button type="submit" className="btn btn-primary">Login</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
