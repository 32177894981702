//FavoriteGames.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GameGroupsMenu from '../elems/GameGroupsMenu';
import GameList from '../elems/GameList';
import Providers from '../elems/Providers';
import { connectWebSocket } from '../../redux/actions/player/listFavoriteGamesActions';
import { selectListFavoriteGames, selectListFavoriteGamesLoading, selectListFavoriteGamesError } from '../../redux/selectors/player/listFavoriteGamesSelectors';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const FavoriteGames = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const listFavoriteGames = useSelector(selectListFavoriteGames);
    const listFavoriteGamesLoading = useSelector(selectListFavoriteGamesLoading);
    const listFavoriteGamesError   = useSelector(selectListFavoriteGamesError);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    useEffect(() => {
        if (isAuthenticated) {
            // dispatch(fetchProviders(token));
            const closeWebSocket = dispatch(connectWebSocket(token));

            return () => {
                closeWebSocket();
            };
        }
    }, [dispatch, token, isAuthenticated]);

    useEffect(() => {
        console.log('listFavoriteGames', listFavoriteGames);
    }, [listFavoriteGames]);

  const handleStarClick = (game) => {
    console.log(`Star clicked for game: ${game.name}`);
  };

  const handleDemoClick = (game) => {
    console.log(`Demo clicked for game: ${game.name}`);
  };

  const handlePlayClick = (game) => {
    console.log(`Play clicked for game: ${game.name}`);
  };
  
  return (
    <>
      <div>
        <h1>Favorite Games.</h1>
        {/* <GameGroupsMenu />
        <Providers /> */}
      </div>

      {listFavoriteGamesLoading ? (
        <p>Loading...</p>
      ) : listFavoriteGamesError ? (
        <p>Error: {listFavoriteGamesError}</p>
      ) :
        <GameList
          gameList={listFavoriteGames}
          onStarClick={handleStarClick}
          onDemoClick={handleDemoClick}
          onPlayClick={handlePlayClick}
        />
      }
    </>
  );

}

// export default withAuth(FavoriteGames);
export default FavoriteGames;