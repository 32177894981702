// config.js

const config = {
  serverUrl: 'https://node-server.spaceduckers.info', // node.js server url
  serverWebsocketPort: 8089,
  serverWebsocketUrl: 'wss://node-server.spaceduckers.info/socket',  //эндпоинт которы обробатывает nginx для подключения к сокету. 
  serverApiPort: 8089,
  apiBaseUrl: 'https://api.sloterra8.com',
  fileUrls: {
    game:  'https://sloterra8.com/upfiles/game/',
    bonusFolder: 'https://sloterra8.com/upfiles/bonus/',
    kycFolder: 'https://sloterra8.com/kyc',
  }
};

module.exports = config;

