//GameGroup.js
import config from '../../config';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import GameGroupsMenu from '../elems/GameGroupsMenu';
import GameList from '../elems/GameList';
import Providers from '../elems/Providers';
// import withAuth from '../hoc/withAuth';  // Import the HOC
import { gameListActions, connectWebSocket } from '../../redux/actions/game/gameListActions';
import { selectGameList, selectGameListLoading, selectGameListError } from '../../redux/selectors/game/gameListSelectors';
import {fetchGameGroups} from '../../redux/actions/game/gameGroupsActions';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const GameGroup = () => {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);
  const groupId = localStorage.getItem('groupId');
  const gameList = useSelector(selectGameList);
  const loading = useSelector(selectGameListLoading);
  const error = useSelector(selectGameListError);

  //Пример. Локальное состояние. loading и error
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  // const [groupId, setGroupId] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      if (groupId) {
        console.log('Group ID:', groupId);
        // dispatch(fetchGameGroups(token, null));
        const closeWebSocket = dispatch(connectWebSocket(token, groupId));
        return () => {
          closeWebSocket();
        };

      } else {
        const closeWebSocket = dispatch(connectWebSocket(token, null));
        return () => {
          closeWebSocket();
        };
      }

    }
  }, [dispatch, token, groupId, isAuthenticated]);

  useEffect(() => {
    // const storedGroupId = localStorage.getItem('groupId');
    // if (storedGroupId) {
    //   setGroupId(storedGroupId);
    // }
    console.log('selectGameList:', gameList);

  }, []);

  const handleStarClick = (game) => {
    console.log(`Star clicked for game: ${game.name}`);
  };

  const handleDemoClick = (game) => {
    console.log(`Demo clicked for game: ${game.name}`);
  };

  const handlePlayClick = (game) => {
    console.log(`Play clicked for game: ${game.name}`);
  };

  return (
    <>
      <div>
        <h1>Game Category: {slug}</h1>
        {/* <GameGroupsMenu />
        <Providers /> */}
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) :
        <GameList
          gameList={gameList}
          onStarClick={handleStarClick}
          onDemoClick={handleDemoClick}
          onPlayClick={handlePlayClick}
        />
      }
    </>
  );
}

// export default withAuth(GameGroup);
export default GameGroup;