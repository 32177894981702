// components/elems/GameGroupsMenu.js
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gameGroupsActions, connectWebSocket } from '../../redux/actions/game/gameGroupsActions';
import { selectGameGroups, selectGameGroupsLoading, selectGameGroupsError } from '../../redux/selectors/game/gameGroupsSelectors';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const GameGroupsMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gameGroups = useSelector(selectGameGroups);
    const gameGroupsLoading = useSelector(selectGameGroupsLoading);
    const gameGroupsError = useSelector(selectGameGroupsError);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    useEffect(() => {
        if (isAuthenticated) {
            // dispatch(fetchProviders(token));
            const closeWebSocket = dispatch(connectWebSocket(token));

            return () => {
                closeWebSocket();
            };
        }
    }, [dispatch, token, isAuthenticated]);

    // Функция для получения уникальных групп из массива активных игр. Хорошо бы создать отдельный экшен в апи для получения списка групп.
    // const getUniqueGroups = (games) => {
    //     const uniqueGroups = [];
    //     const groupMap = new Map();

    //     games.forEach(game => {
    //         if (!groupMap.has(game.group_id)) {
    //             groupMap.set(game.group_id, true);
    //             uniqueGroups.push({
    //                 group_id: game.group_id,
    //                 group_name: game.group_name
    //             });
    //         }
    //     });

    //     return uniqueGroups;
    // };

    // const uniqueGroups = gameGroups ? getUniqueGroups(gameGroups) : [];

    useEffect(() => {
        console.log('gameGroups', gameGroups);
    }, [gameGroups]);

    const handleGroupClick = (group) => {
        if (group.id && group.slug) {
            console.log('Group ID:', group.id);
            console.log('Group Slug:', group.slug);
            localStorage.setItem('groupId', group.id);
            navigate(`/game-group/${group.slug}`, { replace: true });
        } else {
            console.error('Group ID or Slug is missing', group);
        }
    };

    return (
        <div className="block-providers mb-2">
            <div className="card">
                <div className="card-body">
                    <h5>Game categories</h5>
                    {gameGroupsLoading ? (
                        <p>Loading...</p>
                    ) : gameGroupsError ? (
                        <p>Error: {gameGroupsError}</p>
                    ) 
                    : gameGroups !== null ? (
                        <div className="horizontal-scroll-container">
                            {gameGroups.map((group, i) => (
                                <div key={i}>
                                    {/* <Link to={`/game-group/${group.slug}`}>
                                        {group.name}
                                    </Link> */}
                                    <Link
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleGroupClick(group);
                                        }}
                                    >
                                        {group.name}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )
                     : (
                        <p>No game categories!</p>
                    )}

                </div>
            </div>
        </div>
    );
};

export default GameGroupsMenu;
