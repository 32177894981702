import React, {useEffect} from 'react'
import {Box, Paper} from "@mui/material"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {
  clearTransactionsWebSocket,
  fetchTransactions,
  withdrawalsSelector
} from "../../../redux/slices/transactionsSlice"
import sC from "./common/DepositsWithdrawals.module.scss"
import SortableTable from "./common/SortableTable"
import {useFilteredData, useFilters, usePagination, useSorting} from "./common/hooks"
import {Filters} from "./common/Filters/Filters"
import {Pagination} from "./common/Pagination"
import { selectToken } from 'src/redux/selectors/site/loginSelectors'
import {PAGINATION_VALUES, SIBLING_COUNT} from "src/common/constants"

export const Withdrawals = () => {
  const dispatch = useAppDispatch()
  const {data, loading, error} = useAppSelector(withdrawalsSelector)
  // const token = localStorage.getItem('token')
  const token = useAppSelector(selectToken)

  // State for sorting
  const {order, setOrder, orderBy, setOrderBy} = useSorting()

  // Using hook for pagination
  const {currentPage, setCurrentPage, itemsPerPage, setItemsPerPage} = usePagination()

  // Using hook for filters
  const {
    idFilter,
    setIdFilter,
    dateFilter,
    setDateFilter,
    amountFilter,
    setAmountFilter,
    currencyFilter,
    setCurrencyFilter,
    statusFilter,
    setStatusFilter,
  } = useFilters()

  useEffect(() => {
    if (token) dispatch(fetchTransactions({token, direction: 'withdrawal', pageSize: itemsPerPage, page: currentPage}))

    return () => {
      dispatch(clearTransactionsWebSocket('withdrawal'))
    }
  }, [token, dispatch, currentPage, itemsPerPage])

  // Local data filtering
  const filteredData = useFilteredData({
    data: data ? data.transactions.data : [],
    idFilter,
    dateFilter,
    amountFilter,
    currencyFilter,
    statusFilter,
  })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error loading transactions.</p>
  if (!data || !data.transactions.data.length) return <div>No transactions available</div>

  return (
    <Box className={sC.transactionsContainer}>
      <h3>Withdrawals</h3>
      <Box className={sC.filtersContainer}>
        <Filters
          idFilter={idFilter} setIdFilter={setIdFilter}
          dateFilter={dateFilter} setDateFilter={setDateFilter}
          amountFilter={amountFilter} setAmountFilter={setAmountFilter}
          currencyFilter={currencyFilter} setCurrencyFilter={setCurrencyFilter}
          statusFilter={statusFilter} setStatusFilter={setStatusFilter}
        />
      </Box>
      <Paper className={sC.tableContainer}>
        <SortableTable
          data={filteredData} // Display only filtered data
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </Paper>
      <Pagination
        onPageChange={(value) => setCurrentPage(Number(value))}
        totalCount={data.transactions.pagination.total_count}
        currentPage={currentPage}
        pageSize={itemsPerPage}
        siblingCount={SIBLING_COUNT}
        selectSettings={{
          value: itemsPerPage.toString(),
          onChangeOption: (value: string) => {
            setItemsPerPage(Number(value)) // Change the number of items per page
            setCurrentPage(1) // Reset current page to 1
          },
          arr: PAGINATION_VALUES.map((val) => val.toString()),
        }}
      />
    </Box>
  )
}