import React from 'react';

const Home = () => {
    return (
        <div>
            <h1>Home page. Welcome, Guest!</h1>
            {/* Ваш контент для гостей */}
        </div>
    );
};

export default Home;