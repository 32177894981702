import React from 'react';
import { Box, Stack, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import s from './Filters.module.scss';

export const Filters: React.FC<FiltersProps> = ({
                                                  idFilter, setIdFilter,
                                                  dateFilter, setDateFilter,
                                                  amountFilter, setAmountFilter,
                                                  currencyFilter, setCurrencyFilter,
                                                  statusFilter, setStatusFilter
                                                }) => {
  return (
    <Box className={s.filterContainer}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box className={s.filterItem}>
          <TextField
            label="ID"
            variant="outlined"
            value={idFilter}
            onChange={(e) => setIdFilter(e.target.value)}
            fullWidth
          />
        </Box>
        <Box className={`${s.filterItem} ${s.date}`}>
          <TextField
            label="Date"
            variant="outlined"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            fullWidth
          />
        </Box>
        <Box className={`${s.filterItem} ${s.amount}`}>
          <TextField
            label="Amount"
            variant="outlined"
            value={amountFilter}
            onChange={(e) => setAmountFilter(e.target.value)}
            fullWidth
          />
        </Box>
        <Box className={`${s.filterItem} ${s.currency}`}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Currency</InputLabel>
            <Select
              label="Currency"
              value={currencyFilter}
              onChange={(e) => setCurrencyFilter(e.target.value)}
              variant={'outlined'}
            >
              <MenuItem value=""><em>All</em></MenuItem>
              <MenuItem value="Euro">Euro</MenuItem>
              <MenuItem value="United States Dollar">United States Dollar</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className={`${s.filterItem} ${s.status}`}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              variant={'outlined'}
            >
              <MenuItem value=""><em>All</em></MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Box>
  );
};

type FiltersProps = {
  idFilter: string;
  setIdFilter: (value: string) => void;
  dateFilter: string;
  setDateFilter: (value: string) => void;
  amountFilter: string;
  setAmountFilter: (value: string) => void;
  currencyFilter: string;
  setCurrencyFilter: (value: string) => void;
  statusFilter: string;
  setStatusFilter: (value: string) => void;
};
