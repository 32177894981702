import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { fetchBalance, connectBalanceWebSocket } from '../../redux/actions/balanceActions';
import { selectBalance, selectLoading, selectError } from '../../redux/selectors/balanceSelectors';
import GameGroupsMenu from '../elems/GameGroupsMenu';
import Providers from '../elems/Providers';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const balance = useSelector(selectBalance);
  const loading = useSelector(selectLoading);
  const error   = useSelector(selectError);
  // const { balance, loading, error } = useSelector((state) => state.balanceReducer);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('home page token', token);
      // console.log('home page isAuthenticated', isAuthenticated);
      // dispatch(fetchBalance(token));
      const closeBalanceWebSocket = dispatch(connectBalanceWebSocket(token));

      return () => {
        closeBalanceWebSocket();
      };
    }
  }, [dispatch, token, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('home page token', token);
      // console.log('home page isAuthenticated', isAuthenticated);
      // dispatch(fetchBalance(token));
      const closeBalanceWebSocket = dispatch(connectBalanceWebSocket(token));

      return () => {
        closeBalanceWebSocket();
      };
    }
  }, [dispatch, token, isAuthenticated]);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : balance !== null ? (
        <div>
          <h1>Welcome</h1>
          <p>Your balance: {balance}</p>
          <GameGroupsMenu/>
          <Providers/>
        </div>
      ) : (
        <p>No data!</p>
      )}
    </>
  );
}

export default Home;