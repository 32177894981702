// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // reducers.js с корневым редюсером

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // disabled to store WebSocket in redux
      thunk: {
        extraArgument: {
          // Здесь можно передать дополнительные аргументы в thunk
        },
      },
    }),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
