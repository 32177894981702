// listFavoriteGamesReducer.js
import { FETCH_LISTFAVORITEGAMES_REQUEST, FETCH_LISTFAVORITEGAMES_SUCCESS, FETCH_LISTFAVORITEGAMES_FAILURE } from '../../actions/player/listFavoriteGamesActions';

const initialState = {
    listFavoriteGames: [],
    loading: false,
    error: null,
    messages: null,
  };
  
  const listFavoriteGamesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LISTFAVORITEGAMES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_LISTFAVORITEGAMES_SUCCESS:
        return {
          ...state,
          loading: false,
          listFavoriteGames: action.payload,
        };
      case FETCH_LISTFAVORITEGAMES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default listFavoriteGamesReducer;
  