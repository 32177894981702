//Accounts.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectListBalances, selectListBalancesLoading, selectListBalancesError } from '../../redux/selectors/player/listBalancesSelectors';
import { connectListBalancesWebSocket } from '../../redux/actions/player/listBalances';
import withAuth from '../hoc/withAuth';  // Import the HOC
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const Accounts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listBalances = useSelector(selectListBalances);
  const listBalancesLoading = useSelector(selectListBalancesLoading);
  const listBalancesError = useSelector(selectListBalancesError);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const storedToken = useSelector(selectToken);

  useEffect(() => {
    if (isAuthenticated) {
      const closeWebSocket = dispatch(connectListBalancesWebSocket(storedToken));

      return () => {
        closeWebSocket();
      };
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    console.log('player list Balances', listBalances);

    const initialCheckedStates = listBalances?.reduce((acc, balance) => {
      acc[balance.id] = balance.flag_main === 'YES';
      return acc;
    }, {});

    setCheckedStates(initialCheckedStates);

  }, [listBalances]);

  const [checkedStates, setCheckedStates] = useState({});

  // Тут будет обработка при нажатии на плюс или стрелку
  const onClickArrov = async (e) => {
    e.preventDefault();
    console.log(`onClickArrov`);
  };

  const onClickPlus = async (e) => {
    e.preventDefault();
    console.log(`onClickPlus`);
  };

  // Тут потом будет реализовано, что будет при нажатии на чекбоксы
  const handleCheckboxChange = (id) => {
    setCheckedStates(prevStates => ({
      ...prevStates,
      [id]: !prevStates[id]
    }));
    console.log(`Checkbox with id ${id} clicked`);
  };

  return (
    <>
      <div>
        <h1>Accounts</h1>
        {/* <GameGroupsMenu />
            <Providers /> */}
      </div>

      {listBalancesLoading ? (
        <p>Loading...</p>
      ) : listBalancesError ? (
        <p>Error: {listBalancesError}</p>
      ) :
        <div className="row wrap-accounts">

          {listBalances === null || listBalances.length === 0 ? (
            <strong>No data!</strong>
          ) : (
            listBalances.map((balance, i) => (
              <div key={i} className="col-4">
                <div className="card">
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                          <input
                            type="checkbox"
                            id={`checkbox-${balance.id}`}
                            checked={checkedStates[balance.id] || false}
                            onChange={() => handleCheckboxChange(balance.id)}
                          />
                          <h4 className="card-title" style={{ paddingLeft: '10px' }}>
                            {balance.flag_main === 'YES' ? 'Active' : 'Disabled'}
                          </h4>
                        </div>
                        <div className="">
                          <div className="bonus-card-buttons d-flex justify-content-between">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => onClickArrov(e, balance)}
                            >
                              <span className="fa fa-level-down"></span>
                            </button>
                            <button
                              type="button"
                              className="btn btn-warning pl-2"
                              onClick={(e) => onClickPlus(e, balance)}
                            >
                              <span className="fa fa-plus"></span>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <span>Currency</span>
                          <span>{balance.currency_title}</span>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <span>Real</span>
                          <span>{balance.amount}</span>
                        </div>
                      </li>
                      {/* Бонусы пока отсутствуют. */}
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between">
                          <span>Total</span>
                          <span>{balance.amount}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      }
    </>
  );

}

// export default withAuth(Accounts);
export default Accounts;