// listFavoriteGamesActions.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_LISTFAVORITEGAMES_REQUEST = 'FETCH_LISTFAVORITEGAMES_REQUEST';
export const FETCH_LISTFAVORITEGAMES_SUCCESS = 'FETCH_LISTFAVORITEGAMES_SUCCESS';
export const FETCH_LISTFAVORITEGAMES_FAILURE = 'FETCH_LISTFAVORITEGAMES_FAILURE';

// Экшены для получения данных
export const fetchListFavoriteGamesRequest = () => ({
  type: FETCH_LISTFAVORITEGAMES_REQUEST,
});

export const fetchListFavoriteGamesSuccess = (gameList) => ({
  type: FETCH_LISTFAVORITEGAMES_SUCCESS,
  payload: gameList,
});

export const fetchListFavoriteGamesFailure = (error) => ({
  type: FETCH_LISTFAVORITEGAMES_FAILURE,
  payload: error,
});

export const fetchListFavoriteGames = (token) => async (dispatch) => {
  dispatch(fetchListFavoriteGamesRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/player/list-favorite-games`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
    
    dispatch(fetchListFavoriteGamesSuccess(response.data));
  } catch (error) {
    dispatch(fetchListFavoriteGamesFailure(error.message));
  }
};

// Экшены для WebSocket
// export const connectWebSocket = (token, language = null) => {
//   return (dispatch) => {
//     const ws = new WebSocket( config.serverWebsocketUrl );

//     ws.onopen = () => {
//       console.log('Connected to WebSocket server getListFavoriteGames');
//       ws.send(JSON.stringify({ type: 'getListFavoriteGames', token }));
//     };

//     ws.onmessage = (event) => {
//       console.log('WebSocket message received getListFavoriteGames:', event.data);
//       try {
//         const data = JSON.parse(event.data);
//         console.log(data);
//         console.log(data.listFavoriteGames.data);
//         if (data.type === 'getListFavoriteGames') {
//           console.log('ListFavoriteGames received:', data.listFavoriteGames.data);
//           dispatch(fetchListFavoriteGamesSuccess(data.listFavoriteGames.data));
//         } else if (data.type === 'error') {
//           dispatch(fetchListFavoriteGamesFailure(data.message));
//         }
//       } catch (error) {
//         console.error('Error parsing WebSocket message:', error.message);
//       }
//     };

//     ws.onclose = () => {
//       console.log('Disconnected from WebSocket server');
//     };

//     return () => {
//       ws.close();
//     };
//   };
// };

export const connectWebSocket = (token, language = null) => {
  return (dispatch) => {
    const ws = new WebSocket(config.serverWebsocketUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server getListFavoriteGames');
      ws.send(JSON.stringify({ type: 'getListFavoriteGames', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getListFavoriteGames:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed event.data:', data.listFavoriteGames.data.game);

        if (data.type === 'getListFavoriteGames') {
          const favoriteGamesData = data.listFavoriteGames.data.game;
          
          // Преобразуем данные в массив
          // Добавил тля итерации
          const favoriteGamesArray = Array.isArray(favoriteGamesData) ? favoriteGamesData : [favoriteGamesData];

          console.log('ListFavoriteGames received (array):', favoriteGamesArray);
          dispatch(fetchListFavoriteGamesSuccess(favoriteGamesArray));
        } else if (data.type === 'error') {
          dispatch(fetchListFavoriteGamesFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
