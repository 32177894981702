//Documents.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { connectWebSocketListDocuments, fetchListDocuments } from '../../redux/actions/document/getListDocumentsActions';
import { selectListDocuments, selectListDocumentsLoading, selectListDocumentsError } from '../../redux/selectors/document/getListDocumentsSelectors';
import { connectWebSocketGetDocumentSettingTypes, fetchDocumentSettingTypesActions } from '../../redux/actions/document/getDocumentSettingTypesActions';
import { selecttDocumentSettingTypes, selecttDocumentSettingTypesLoading, selecttDocumentSettingTypesError } from '../../redux/selectors/document/getDocumentSettingTypesSelectors';
import withAuth from '../hoc/withAuth';  // Import the HOC
import axios from 'axios';
import config from '../../config';
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';

const Documents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listDocuments = useSelector(selectListDocuments);
  const listDocumentsLoading = useSelector(selectListDocumentsLoading);
  const listDocumentsError = useSelector(selectListDocumentsError);

  const documentSettingTypes = useSelector(selecttDocumentSettingTypes);
  const documentSettingTypesLoading = useSelector(selecttDocumentSettingTypesLoading);
  const documentSettingTypesError = useSelector(selecttDocumentSettingTypesError);

  // Пример. Потом ошибки и загрузку выводим из хранилища.
  // const loading = useSelector(selectLoading);
  // const error = useSelector(selectError);

  const loading = false;

  const [file, setFile] = useState(null);
  const [doctype, setDoctype] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [imageLoadStatus, setImageLoadStatus] = useState({});
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchListDocuments(token));
      const closeWebSocketListDocuments = dispatch(connectWebSocketListDocuments(token));

      // dispatch(fetchDocumentSettingTypesActions(token));
      const closeWebSocketGetDocumentSettingType = dispatch(connectWebSocketGetDocumentSettingTypes(token));

      return () => {
        closeWebSocketListDocuments();
        closeWebSocketGetDocumentSettingType();
      };
    }
  }, [dispatch, token, isAuthenticated]);

  useEffect(() => {
    console.log('listDocuments', listDocuments);
    console.log('token', token);
  }, [listDocuments]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDoctypeChange = (event) => {
    setDoctype(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = async () => {
    if (!file || !doctype) {
      setError('Please select a file and document type.');
      return;
    }

    setError(null);
    setSuccess(null);

    const formData = new FormData();
    formData.append('document', file);
    formData.append('doctype', doctype);

    try {
      const response = await axios.post(`${config.apiBaseUrl}/document/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(response.data.success);
      console.log(response.data.errors);
      if (response.data.success) {
        setSuccess('File uploaded successfully!');
        setFile(null); // Сброс инпут файла
        setDoctype(''); // Сброс инпут типа документа
        window.location.reload();
      } else {
        setError(response.data.errors ? JSON.stringify(response.data.errors) : 'Unknown error occurred.');
      }
    } catch (error) {
      setError('An error occurred during the upload.');
      console.log(error);
    }
  };

  // Это для проверки того, чтобы если урл картинки есть а в папке ее не существует, выводить сообщение.
  const handleImageLoad = (docId) => {
    setImageLoadStatus(prevStatus => ({ ...prevStatus, [docId]: true }));
  };

  const handleImageError = (docId) => {
    setImageLoadStatus(prevStatus => ({ ...prevStatus, [docId]: false }));
  };

  return (
    <>
      <h1>Documents</h1>

      <div className="alert alert-primary" role="alert">
        Verification required! Please download the following documennts: Passport / ID card / Residence confirmation.
      </div>
      <div className="row wrap-documents">
        {/* download file block */}
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Upload</h4>
              <input type="file" onChange={handleFileChange} />
              <p></p>
              <select value={doctype} onChange={handleDoctypeChange}>
                <option value="">Select Document Type</option>
                {
                  documentSettingTypesLoading ? (
                    <p>Loading...</p>
                  ) : documentSettingTypesError ? (
                    <p>Error: {documentSettingTypesError}</p>
                  ) :
                    documentSettingTypes.length === 0 ? (
                      <option value="" disabled>
                        No data
                      </option>
                    ) : (
                      documentSettingTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))
                    )}
              </select>

              <p></p>
              <button onClick={handleSubmit} className="btn btn-success">Upload</button>
              {error && <div style={{ color: 'red' }}>{error}</div>}
              {success && <div style={{ color: 'green' }}>{success}</div>}
              <p></p>

              <p className="card-text">Only jpg,jpeg, png, gif, pdf, up to 10 MB can be oploaded. The file name must be less then 50 symbols.</p>
            </div>
          </div>

        </div>

        {listDocumentsLoading ? (
          <p>Loading...</p>
        ) : listDocumentsError ? (
          <p>Error: {listDocumentsError}</p>
        ) :

          listDocuments.length > 0 ? (
            <div className="col-8">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Your documents</h4>

                  <div className="row wrap-all-documents">
                    {listDocuments.map((doc, i) => (
                      <div key={i} className="col-6">



                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">{doc.type}</h5>
                            <hr></hr>
                            {
                              doc.docfile_path && imageLoadStatus[doc.id] !== false ? (
                                <div className='item-game_pic'>
                                  <img
                                    className='img-thumbnail'
                                    src={`${config.fileUrls.kycFolder}/${doc.docfile_path}`}
                                    alt={doc.type}
                                    onLoad={() => handleImageLoad(doc.id)}
                                    onError={() => handleImageError(doc.id)}
                                  />
                                </div>
                              ) : (
                                <p>No image...</p>
                              )
                            }

                            <p>{doc.id}</p>
                            <p>{doc.datetime_client}</p>
                            <p>
                              <strong>{doc.status}</strong>
                            </p>
                          </div>
                        </div>


                      </div>


                    ))}



                  </div>

                </div>
              </div>
            </div>
          )
            : (
              <div className="col-4">
                <p>No documents!</p>
              </div>
            )






        }
      </div >
    </>
  );
}

// export default withAuth(Documents);
export default Documents;